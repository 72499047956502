.memory-container {
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background:#130f2a;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    padding:1.5rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;

}

.card-title {
    text-align: center;
    margin-bottom: 1rem;
}

.card-body {
    margin-top: 1rem;
}

.image-container img {

    overflow: hidden;
    border-radius: 0.5rem;
    height: 400px;
}

@media (max-width: 765px) {
    .image-container img {
        height: 200px;
    }

    .card-body {
        font-size: 13px;
    }
}